import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"


class FaqsPage extends React.Component {

  render() {

    const rFreeKicks = this.props.data.allSheetRonaldoAllTimeStats.edges.map(d => d.node.freeKicks)

    const mTrophies = this.props.data.allSheetHonours.edges.map(d => d.node.mcount)
    const rTrophies = this.props.data.allSheetHonours.edges.map(d => d.node.rcount)

    return (

      <Layout>
        <SEO
          title={"Frequently Asked Questions - Messi vs Ronaldo"}
          description={`Why do assist stats vary by source? What exactly is an Opta assist? Why is this stat not what I expected it to be? Find the answers to all your questions right here!`}
          canonicalPath={`/faqs/`}
        />

        <h1>Frequently Asked Questions</h1>

        

        <div class="mt-8 lg:mt-12">
          <CopyBlock>
            <h2>Assists</h2>

            <h3>Why are your assist stats different to this other source?</h3>

            <p>Assist stats vary by source. There is no "official" assist definition, but the assist definition from Opta stats is the most widely used/accepted in football - they are the official data providers for many competitions and organisations such as the Premier League, La Liga, the BBC, WhoScored, SofaScore etc.</p>

            <p>We therefore try to align all of our assist stats with Opta's.</p>

            <h3>What exactly is an Opta assist?</h3>

            <p>Most of the time, when you're looking at assist stats, you're looking at assists that have been collected by Opta. Most TV graphics, La Liga website, Premier League website, WhoScored, the BBC; these sources will all use Opta as their source for assists.</p>

            <p>Opta's assist definition is as follows:</p>

            <blockquote>
              <p>The final touch (pass, pass-cum-shot or any other touch) leading to the recipient of the ball scoring a goal. If the final touch (as defined in bold) is deflected by an opposition player, the initiator is only given a goal assist if the receiving player was likely to receive the ball without the deflection having taken place. Own goals, directly taken free kicks, direct corner goals and penalties do not get an assist awarded.</p>
            </blockquote>

            <h3>Why are Transfermarkt's assist stats so different?</h3>

            <p>As explained above, Opta's assist definiton is the most widely used and accepted in football. Transfermarkt on the other hand are much more loose with their assist definition; they count deflected passes, rebounded shots, penalties won, and own goal assists.</p>

            <p>That's why their assist stats are more inflated than other sources. You will see Transfermarkt's assist numbers quite frequently on social media because their data is really accessible - it's the only well known source where you can easily get career stats for any player.</p>

            <p>However they are seemingly very inconsistent in terms of awarding assists and there are subsequently many holes in their data. You can view a full list of all assists that Transfermarkt are missing from their records over on our <Link to={`/info/`} className="text-highlight underline">Info</Link> page.</p>

            <p>Although we use the Opta assist definition throughout this site, we do have a single page that lists all types of assist, including deflected passes, goalkeeper rebounds, post rebounds, penalties won (scored by others) and own goal assists: <Link to={`/detailed-stats/all-assists/`} className="text-highlight underline">All Assists (+ non-Opta)</Link></p>

            <h3>Why are your Champions League assist stats different to the UEFA website?</h3>

            <p>The assist list on UEFA's website includes qualifying - Ronaldo has 1 assist in Champions League Qualifiers (for Manchester United), so that's why he has 1 more assist on the UEFA website.</p>

            <h3>How many assists did Ronaldo make in Euro 2016?</h3>

            <p>UEFA credit Ronaldo with 3 assists at Euro 2016 which includes a goalkeeper rebound after his shot was saved before Quaresma's goal vs Croatia. UEFA are one of the few major organisations in football that don't use Opta as their data provider; a rebounded shot does not count as an Opta assist. Ronaldo therefore made 2 assists at Euro 2016 according to the Opta definition.</p>

            <h3>Why is your Messi assist total different to Opta's?</h3>

            <p>From various Opta <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/OptaJose/status/1276916910496649222">tweets</a>, it's evident that they are missing 2 Copa del Rey assists for Messi from their records.</p>

            <p>However upon checking all of them, we can confirm that they all fit the definition, with none of them appearing to be even questionable. It's possible that Opta may not have covered certain Copa games throughout Messi's career, such as ones from earlier rounds, so may be missing statistics from these games.</p>

            <h2>Outside the Box Goals</h2>

            <h3>Why is this goal listed as inside the box? It looks outside to me!</h3>

            <p>A goal is only considered as outside of the box if the <em>whole</em> of the ball is outside the box when the ball leaves the shooting player's foot. This means that even if the ball is slightly touching the 18 yard line, it goes down as an inside the box goal.</p>

            <p>If you're unsure whether a goal was inside or outside, you can view the fixture on WhoScored and look at the shot location using their Chalkboard feature. This data comes straight from Opta.</p>

            <p>However, we know of at least one Opta mistake: they marked a Ronaldo goal vs Dortmund (14/10/2012) as inside the box - footage shows this was clearly outside the box.</p>

            <h3>Why does this video show Ronaldo with a lot more outside the box goals?</h3>

            <p>We have been sent <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?app=desktop&t=30s&v=pUQWvLO1CWc">this video</a> a number of times, which suggests that Ronaldo has many more outside the box goals than the stats suggest.</p>

            <p>The truth is that many of the goals in this video are in friendlies or are simply inside the box. Here's a full breakdown of the video:</p>

            <ul>
              <li>0:00 vs Moreirense - inside box</li>
              <li>1:26 vs ? - friendly</li>
              <li>1:44 vs Oxford - friendly</li>
              <li>1:51 vs Sevilla - friendly</li>
              <li>2:20 vs European XI - charity game</li>
              <li>2:39 vs Seoul - friendly</li>
              <li>4:58 vs Marseille - inside box</li>
              <li>Video missing a free kick goal vs Getafe 25/03/2010</li>
              <li>5:25 vs Club America - friendly</li>
              <li>6:32 vs Hertha - friendly</li>
              <li>9:08 vs Bournemouth - friendly</li>
              <li>9:13 vs Chelsea - friendly</li>
              <li>9:21 vs Inter - friendly</li>
              <li>9:44 vs Sevilla - inside box</li>
              <li>10:58 vs AC Milan - friendly</li>
            </ul>

            <h2>Trophies</h2>

            <h3>How many trophies have Messi and Ronaldo won exactly?</h3>

            <p>You will often see quite different total trophy counts on social media for both Messi and Ronaldo, but why?</p>

            <p>The main reason is because there are some Super Cup wins they have not participated in; some people count these as trophies won, some people don't. It simply depends on your opinion, but most sources <em>do</em> count these.</p>

            <p>Messi was not in the squad for Barcelona's successful 2005 Supercopa de Espana final.</p>

            <p>Ronaldo was an unused sub for Sporting's Super Cup win in 2002. He was also not in the squad for Manchester United's Community Shield win in 2008, or for Real Madrid's UEFA Super Cup win in 2016.</p>

            <p>Another reason for differing total trophy counts is that some sources will include Messi's Olympic Gold Medal (which he won with an U23 Argentina side) and his FIFA U20 World Cup win, and some sources will not.</p>

            <h4>All career titles (including youth):</h4>
            <ul>
              <li>Messi: {mTrophies} trophies</li>
              <li>Ronaldo: {rTrophies} trophies</li>
            </ul>

            <h4>Discounting Messi's Olympic Gold & U20 World Cup:</h4>
            <ul>
              <li>Messi: {mTrophies-2} trophies</li>
              <li>Ronaldo: {rTrophies} trophies</li>
            </ul>

            <h4>Super Cups they were not in the squad for:</h4>
            <ul>
              <li>Messi: 1</li>
              <li>Ronaldo: 2</li>
            </ul>

            <h4>Super Cups as unused sub:</h4>
            <ul>
              <li>Messi: 0</li>
              <li>Ronaldo: 1</li>
            </ul>

            <h2>Performance Stats</h2>

            <h3>Where do you source your performance stats from?</h3>

            <p>All of the performance stats on thzis site, such as shots, key passes, successful dribbles etc. come from sources that use Opta as their data provider, such as WhoScored, SofaScore and FotMob. They are updated after each game but can be tweaked a day later following Opta's post-game checks.</p>

            <h3>Where do your Man of the Match stats come from?</h3>

            <p>The ratings and man of the match stats on this site are based on WhoScored's match rating system, apart from a handful of games they don't cover (such as early round Copa del Rey games and some internationals) in which case SofaScore is used.</p>

            <p>An official MOTM award has only been given in certain competitions in certain seasons, and are very difficult to find in retrospect. Using WhoScored MOTMs means we have this data for around 700 games for each player - if we tried to use "official" MOTM awards, we would likely only have around 100, and they would be for quite random competitions and seasons, so would be make comparisons difficult to impossible.</p>

            <p>Using an algorithm for ratings and for "man of the match" awards also ensures consistency across all competitions and removes the human error / bias element. Further to this, many of the "official" awards given these days are based on fan votes.</p>

          </CopyBlock>
        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetRonaldoAllTimeStats(filter: {competition: {eq: "All Time Career"}}) {
      edges {
        node {
          competition
          freeKicks
        }
      }
    }
    allSheetHonours(filter: {honour: {eq: "Total Trophies"}}) {
      edges {
        node {
          honour
          rcount
          mcount
        }
      }
    }
  }
`

export default FaqsPage

import React from "react"
//import { Link } from "gatsby"

import styles from "./CopyBlock.module.css"


class CopyBlock extends React.Component {

  render() {

    return (

      <article id={this.props.id} className={`${styles.CopyBlock} copy-block w-full mt-6 text-gray-400 ${this.props.classes}`}>
        <div className={`max-w-md p-6 mx-auto ${this.props.bg === 'light' ? 'bg-white text-noir' : 'bg-noir-lighten5'}`} itemScope={this.props.type === 'faq' ? true : false} itemProp={this.props.type === 'faq' ? 'mainEntity' : ''} itemType={this.props.type === 'faq' ? 'https://schema.org/Question' : ''}>
          {this.props.children}
        </div>
      </article>

    )
  }
}

// CopyBlock.defaultProps = {
//   margins: "mt-4 xs:mt-6",
//   padding: "py-2 pl-4 pr-3",
// };

export default CopyBlock